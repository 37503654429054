import { Box } from "@chakra-ui/react";
import Image, { StaticImageData } from "next/image";
import React from "react";

import { CustomLink } from "@components/atoms";
import { P } from "@components/atoms/Text/P";
import { defaultTheme } from "@styles";

interface CollectionItemProps {
  backgroundImage: StaticImageData;
  name: string;
  path?: string;
  url?: string;
}

const CollectionItem: React.FC<CollectionItemProps> = ({
  url,
  backgroundImage,
  name,
  path,
}) => (
  <CustomLink url={url} path={path}>
    <Box
      w={["11.5rem", "11.5rem", null, "20rem"]}
      h={["7rem", null, "8rem"]}
      position="relative"
      cursor="pointer"
      mr={["1rem", "1rem", null, null]}
    >
      <Image
        src={backgroundImage.src}
        layout="fill"
        alt={name}
        objectFit="cover"
        style={{
          filter: "grayscale(100%)",
        }}
      />
      <P
        position="absolute"
        top="50%"
        left="50%"
        padding="0"
        color="primary"
        transform="translate(-50%, -50%)"
        w="100%"
        textAlign="center"
        fontSize={defaultTheme.typography.h3FontSize}
        overflowWrap="break-word"
      >
        {name}
      </P>
    </Box>
  </CustomLink>
);

export default CollectionItem;
