/* eslint-disable import/no-unresolved */
// temporary fix https://github.com/eslint/eslint/issues/15171
import { Box } from "@chakra-ui/react";
import React from "react";
import {
  A11y,
  FreeMode,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Button } from "@components/atoms";

import "swiper/css";

const buttonNextPrevProps = {
  zIndex: 1,
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "50%",
  transform: "translateY(-50%)",
  cursor: "pointer",
  width: ["3.125rem", null, null, "4rem"],
  height: ["3.125rem", null, null, "4rem"],
  bgColor: "black",
  p: "0.9rem 0.9rem",
  borderRadius: 0,
};

const svgNextPrevProps = {
  as: "svg",
  w: ["1.5rem", null, null, "1.8rem"],
  h: ["1.5rem", null, null, "1.8rem"],
  color: "white",
  position: "absolute",
} as any;

export const ArrowLeft = () => (
  <Button
    className="swiper-button-prev"
    aria-label="previous carousel button"
    left="0rem"
    {...buttonNextPrevProps}
  >
    <Box viewBox="137.718 -1.001 366.563 644" {...svgNextPrevProps}>
      <path
        fill="white"
        d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z"
      />
    </Box>
  </Button>
);

export const ArrowRight = () => (
  <Button
    className="swiper-button-next"
    aria-label="next carousel button"
    right="0rem"
    {...buttonNextPrevProps}
  >
    <Box viewBox="0 0 238.003 238.003" {...svgNextPrevProps}>
      <path
        fill="white"
        d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z"
      />
    </Box>
  </Button>
);

interface CarouselProps {
  slides: React.ReactNode[];
  hideButtons?: boolean;
  spaceBetween?: number;
}

export const Carousel = ({
  slides,
  hideButtons,
  spaceBetween,
}: CarouselProps) => (
  <Swiper
    modules={[Navigation, Pagination, Scrollbar, A11y, FreeMode]}
    spaceBetween={spaceBetween || 5}
    loop
    freeMode
    slidesPerView="auto"
    pagination={{ clickable: true }}
    scrollbar={{ draggable: true }}
    navigation={{
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    }}
  >
    {!hideButtons && (
      <>
        <ArrowLeft />
        <ArrowRight />
      </>
    )}

    {slides.map((slide, i) => (
      <SwiperSlide style={{ width: "auto" }} virtualIndex={i} key={i}>
        {slide}
      </SwiperSlide>
    ))}
  </Swiper>
);
