import { Box } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";
import { generatePath } from "react-router";

import { H2 } from "@components/atoms/Text/H2";
import { P } from "@components/atoms/Text/P";
import { Carousel } from "@components/organisms/Carousel";
import CircularPartnerItem from "@components/organisms/CircularPartners/CircularPartnerItem";
import { paths } from "@paths";

import AcoDesktop from "images/circular-partners/aco-desktop.png";
import Aco from "images/circular-partners/aco-mobile.png";
import AdamHeathDesktop from "images/circular-partners/adam-heath-desktop.png";
import AdamHeath from "images/circular-partners/adam-heath-mobile.png";
import AlemaisDesktop from "images/circular-partners/alemais-desktop.png";
import Alemais from "images/circular-partners/alemais-mobile.png";
import GingerDesktop from "images/circular-partners/ginger-and-smart-desktop.png";
import Ginger from "images/circular-partners/ginger-and-smart-mobile.png";
import KateFord from "images/circular-partners/kate-ford.jpg";
import KitxDesktop from "images/circular-partners/kitx-desktop.png";
import Kitx from "images/circular-partners/kitx-mobile.png";

const partners = [
  {
    name: "KITX",
    backgroundImage: Kitx,
    backgroundImageDesktop: KitxDesktop,
    path: generatePath(paths.designer, { slug: "kitx" }),
  },
  {
    name: "ALEMAIS",
    backgroundImage: Alemais,
    backgroundImageDesktop: AlemaisDesktop,
    path: generatePath(paths.designer, { slug: "alemais-hire" }),
  },
  {
    name: "ADAM HEATH",
    backgroundImage: AdamHeath,
    backgroundImageDesktop: AdamHeathDesktop,
    url: generatePath(paths.designer, { slug: "adam-heath-hire" }),
  },
  {
    name: "ACO",
    backgroundImage: Aco,
    backgroundImageDesktop: AcoDesktop,
    url: generatePath(paths.designer, { slug: "aco-hire" }),
  },
  {
    name: "KATE FORD",
    backgroundImage: KateFord,
    backgroundImageDesktop: KateFord,
    url: generatePath(paths.designer, { slug: "kate-ford-hire" }),
  },
  {
    name: "GINGER & SMART",
    backgroundImage: Ginger,
    backgroundImageDesktop: GingerDesktop,
    url: generatePath(paths.designer, { slug: "ginger-smart-hire" }),
  },
];

export const CircularPartners: React.FC = () => (
  <Box
    w="full"
    textAlign="left"
    padding={["1.5rem 0 1.5rem 0.93rem", null, null, "3.75rem 8rem"]}
    backgroundColor="black"
  >
    <Box
      display="flex"
      flexDirection={["column", null, null, "row"]}
      w="full"
      justifyContent="space-between"
      alignContent="center"
      color="primary"
    >
      <H2 mb="1.5rem">OUR CIRCULAR PARTNERS</H2>
      <Link href={paths.partnerPromo} passHref>
        <a>
          <P
            _hover={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            mt="1rem"
          >
            VIEW ALL CIRCULAR PARTNERS
          </P>
        </a>
      </Link>
    </Box>
    <Carousel
      slides={partners.map((partner) => (
        <CircularPartnerItem
          backgroundImage={partner?.backgroundImageDesktop}
          name={partner.name}
          url={partner.url}
          path={partner.path}
        />
      ))}
    />
  </Box>
);
